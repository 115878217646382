"use strict";
$(function ($) {
  // ハンバーガーメニュー
  $(".js-hamburger").click(function () {
    $(this).toggleClass("is-open");
    $(".js-drawer").fadeToggle();
  });

  // ドロワーナビのaタグをクリックで閉じる
  $(".js-drawer a[href]").on("click", function () {
    $(".js-hamburger").removeClass("is-open");
    $(".js-drawer").fadeOut();
  });

  // resizeイベント
  $(window).on("resize", function () {
    if (window.matchMedia("(min-width: 750px)").matches) {
      $(".js-hamburger").removeClass("is-open");
      $(".js-drawer").fadeOut();
    }
  });

  //   アコーディオン
  $(".js-qa-question").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("is-open");
  });

  // ページトップ
  const floating = $("#js-pagetop");
  floating.hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 70) {
      floating.fadeIn();
    } else {
      floating.fadeOut();
    }
  });

  // モーダル
  // $(function () {
  //   $('#openModal').click(function(){
  //       $('#modalArea').fadeIn();
  //   });
  //   $('#closeModal , #modalBg').click(function(){
  //     $('#modalArea').fadeOut();
  //   });
  // });

  let isModalOpen = false;
let scrollPosition;

$('#openModal').on('click', function() {
  if (!isModalOpen) {
    scrollPosition = $(window).scrollTop();
    $('body').addClass('modal-open').css({'top': -scrollPosition + 'px'});
    $('#modalArea').fadeIn();
    isModalOpen = true;
  }
});

$('#closeModal, #modalBg').on('click', function() {
  if (isModalOpen) {
    $('body').removeClass('modal-open').css({'top': ''});
    window.scrollTo(0, scrollPosition);
    $('#modalArea').fadeOut();
    isModalOpen = false;
  }
});

});


